<template>
  <div class="FlateCart">
    <v-container>
      <v-row>
        <v-col align="left" cols="6">
          <v-checkbox
            v-model="allSelected"
            color="primary"
            label="Select all"
            @click="selectAll(items)"
          ></v-checkbox>
        </v-col>
        <v-col align="right" cols="6">
          <v-btn text color="error" v-on:click="delAll"
            >{{ $t("Delete") }}
          </v-btn>
        </v-col>
      </v-row>

      <RouteInformation
        :dialog="notifRoute"
        :ToDest="routeInformation.route_loc"
        :ToOrig="routeInformation.route_loc"
        :destination="routeInformation.destination_loc"
        :origin="routeInformation.original_loc"
        :routeTitle="routeInformation.cart_item_name"
        :orig="routeInformation.orig_loc"
        :dest="routeInformation.dest_loc"
        :titleheadlineCard="routeInformation.route_loc"
        :btnClose="closeRouteInformation"
      />
      <v-row no-gutters v-for="(item, index) in items" :key="index">
        <v-col cols="1">
          <v-checkbox
            v-model="selected"
            color="primary"
            :value="item"
            @click="select"
          ></v-checkbox>
        </v-col>
        <v-col cols="11">
          <!-- card -->
          <div class="component-1" v-if="item.cart_fare_type === '01'">
            <p class="route-1">{{ item.cart_item_name }}</p>
            <div class="flex-wrapper-three">
              <p class="bth">{{ item.orig_loc }}</p>
              <div class="relative-wrapper-one">
                <img
                  alt=""
                  class="vector"
                  :src="require('@/assets/buy-ticket/bus-vector.svg')"
                />
              </div>
              <p class="bth">{{ item.dest_loc }}</p>
            </div>
            <div class="flex-wrapper-four">
              <p class="bn-thanh">{{ item.original_loc }}</p>
              <p class="bn-bbc">{{ item.destination_loc }}</p>
            </div>
            <img
              alt=""
              class="vector-7"
              :src="require('@/assets/buy-ticket/vektor.svg')"
            />
            <div class="flex-wrapper-five">
              <div class="flex-wrapper-six">
                <p
                  v-on:click="clickRouteInformation(item)"
                  class="route-information"
                >
                  {{$t("RouteInformation")}}
                </p>
                <p class="flat-fare-ticket-is-valid-for-24-hours">
                  {{$t("SingleTripTicketIsValidForTwentyFourHours")}}<!-- {{ time }} -->
                </p>
              </div>
              <p class="num-135000-v-nd">
                {{ item.cart_original_price * item.cart_qty }}
                {{ item.fare_currency }}
              </p>

              <v-icon class="danger--text" v-on:click="delItem(item)"
                >mdi-delete</v-icon
              >
              <MinusPlusButtonCart v-model="item.cart_qty" />
            </div>
          </div>
          <!-- card  multidays-->
          <div v-if="item.cart_fare_type === '03'" class="component-1">
            <p class="all-route-1">{{$t("AllRoute")}}</p>
            <div class="flex-wrapper-name-multidays">
              <p class="DaysTicket">{{ item.cart_item_name }}</p>
              <p class="price-multidays">
                {{ item.cart_original_price * item.cart_qty }}
              </p>
            </div>
            <div class="flex-wrapper-trip-multidays">
              <v-row class="row-trip-multidays">
                <div class="titik"></div>
                <p class="trip-exp">{{ item.max_trip_perday }} {{$t("Trips_day")}}</p>
                <div class="titik"></div>
              </v-row>
            </div>
            <img
              alt=""
              class="vector-7-multidays"
              :src="require('@/assets/buy-ticket/vektor.svg')"
            />
            <div class="flex-wrapper-route-information">
              <div class="flex-wrapper-route-information-2">
                <p
                  v-on:click="clickRouteInformation(item)"
                  class="route-information"
                >
                  {{$t("RouteInformation")}}
                </p>
                <p class="flat-fare-ticket-is-valid-for-24-hours">
                  {{$t("Max")}} {{ item.max_trip }} {{$t("Trip")}},{{
                    item.max_trip_perday
                  }}
                  {{$t("Trips_day")}}<!-- {{ time }} -->
                </p>
              </div>

              <v-icon
                class="delete-card-multidays danger--text"
                v-on:click="delItem(item)"
                >mdi-delete</v-icon
              >
              <MinusPlusButtonCart v-model="item.cart_qty" />
            </div>
          </div>
          <!-- end card  multidays-->
        </v-col>
      </v-row>

      <v-list-item class="grow">
        <p class="primary--text">{{$t("Total")}}:</p>
        <v-row align="center" justify="end">
          <p class="black--text">{{ this.total }}</p>
        </v-row>
      </v-list-item>
      <div class="footer">
        <v-container>
          <v-btn
            rounded
            width="100%"
            class="text-capitalize primary"
            v-on:click="checkOut(selected)"
            >{{ $t("CheckOut") }}
          </v-btn>
        </v-container>
      </div>
      <!-- <p>{{selected}}</p> -->
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import MinusPlusButtonCart from "@/components/MinPlusButtonCart.vue";
import RouteInformation from "@/components/RouteInformation.vue";

export default {
  name: "FlateCart",
  components: {
    MinusPlusButtonCart,
    RouteInformation,
  },
  data() {
    return {
      notifRoute: false,
      timestamp: "",
      selected: [],
      allSelected: false,
      notifSuccess: false,
      notifFailed: false,
      total: 0,
      routeInformation: {},
      items: [],
      psg_detail: store.state.auth.userData.user_name,
      psg_id: store.state.auth.userData.psg_id,
    };
  },

  methods: {
    clickRouteInformation(itemRoute) {
      this.routeInformation = itemRoute;
      this.notifRoute = true;
    },
    closeRouteInformation() {
      this.notifRoute = false;
    },
    refreshItem: function () {
      const payload = {
        cart_psg_id: this.psg_id,
      };
      this.$store
        .dispatch("purchaseticket/getCart", payload)
        .then((response) => {
          this.items = [];
          for (let data of response.data) {
            this.items.push(data);
            this.selected = [];
            this.allSelected = false;
            this.total = 0;
            // console.log("data asa",data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delItem(item) {
      const payload = {
        cart_psg_id: this.psg_id,
        cart_fare_id: item.cart_fare_id,
      };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.cart_qty = 0
          this.refreshItem;
          if (response.status) console.log("success");
        })
        .catch((err) => {
          this.notifFailed = true;
          console.log(err);
        });
    },
    delAll() {
      const payload = {
        cart_psg_id: this.psg_id,
        cart_fare_id: "",
      };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.cart_qty = 0
          this.refreshItem;
          if (response.status) console.log("success");
        })
        .catch((err) => {
          this.notifFailed = true;
          console.log(err);
        });
    },
    updateItem(item, cart_qty) {
      const payload = {
        cart_psg_id: this.psg_id,
        cart_fare_uuid: item.cart_fare_uuid,
        cart_qty: "" + cart_qty + "",
      };
      this.$store
        .dispatch("purchaseticket/updateItem", payload)
        .then((response) => {
          this.items = [];
          for (let data of response.data) {
            this.items.push(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkOut(selected) {
      // console.log(selected)
      const today = new Date();
      const date =
        today.getFullYear() +
        "" +
        (today.getMonth() + 1) +
        "" +
        today.getDate();
      const time =
        today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      const dateTime = date + "" + time;
      this.timestamp = dateTime;
      const payload = {
        inv_no: "ACASIA-" + this.timestamp + "" + this.psg_id + "",
        inv_psg_id: this.psg_id,
        inv_psg_detail: this.psg_detail,
        inv_amount: "" + this.total + "",
        inv_detail: selected,
      };
      this.$router.push({ name: "Checkout", params: { data: payload } });
    },
    // checkOut(selected){
    //   // console.log(selected)
    //   const today = new Date();
    //   const date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
    //   const time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
    //   const dateTime = date +''+ time;
    //   this.timestamp = dateTime;
    //   const payload = {
    //     inv_no:'ACASIA-'+this.timestamp+''+this.psg_id+'',
    //     inv_psg_id:this.psg_id ,
    //     inv_psg_detail:this.psg_detail ,
    //     inv_amount:''+this.total+'' ,
    //     inv_detail:selected ,
    //   };
    //     this.$store
    //       .dispatch("purchaseticket/checkout", payload)
    //       .then((response) => {
    //         this.refreshItem()
    //       if (response.status) //console.log("success");
    //       // this.$router.push({ name: "MyTicket" });
    //         window.location.href=response.url
    //       })
    //       .catch((err) => {
    //         this.notifFailed = true
    //         console.log(err);
    //       });
    //   },
    buttonOke() {
      this.notifSuccess = false;
      this.item.cart_qty = 0;
      if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    selectAll(item) {
      this.selected = [];
      if (this.allSelected) {
        this.selected = item;
        var total = 0; //set a variable that holds our total
        var data = this.selected; //reference the element in the "JSON" aka object literal we want
        var i = 0;
        for (i = 0; i < data.length; i++) {
          //loop through the array
          total += data[i].cart_price_after_discount * data[i].cart_qty; //Do the math!
        }
        this.total = total;
      } else this.total = 0;
    },
    select: function () {
      this.allSelected = false;
      var total = 0; //set a variable that holds our total
      var data = this.selected; //reference the element in the "JSON" aka object literal we want
      var i = 0;
      for (i = 0; i < data.length; i++) {
        //loop through the array
        total += data[i].cart_price_after_discount * data[i].cart_qty; //Do the math!
      }
      this.total = total;
    },
  },
  // watch:{
  //   this.$store.state.auth.userData.user_name
  // },
  mounted() {
    const payload = {
      cart_psg_id: this.psg_id,
    };
    this.$store
      .dispatch("purchaseticket/getCart", payload)
      .then((response) => {
        this.items = [];
        for (let data of response.data) {
          this.items.push(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style lang="scss" scoped>
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #ffffff 0%, #ffe9fc 100%);
}

.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #ffffff;
  mix-blend-mode: normal;
  /* Field */

  box-shadow: 0px 1px 10px 0px;
}

.btn-cart {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

// card
/* If the screen size is 673px or more, set the font-size of <div> to 80px */
// @media only screen and (max-width: 600px) {
.component-1 {
  padding: 10px 14px 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url("~@/assets/buy-ticket/bg-card.svg");
}
.cardCart {
  background-color: #f5fdf7;
  display: flex;
  flex-wrap: nowrap;
}

.route-1 {
  color: rgba(134, 134, 134, 1);
  margin-bottom: 1px;
  margin-left: 150px;
}
.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 25px;
  display: flex;
  align-items: center;
}
.bth {
  font-size: 24px;
  color: rgba(72, 158, 75, 1);
  &:not(:last-of-type) {
    margin-right: 30px;
  }
}
.relative-wrapper-one {
  margin-right: 31px;
  position: relative;
}
.vector {
  position: relative;
}
.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}
.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
}
.bn-thanh {
  margin-top: -15px;
  margin-left: 15px;
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
  &:not(:last-of-type) {
    margin-right: 135px;
  }
}
.bn-bbc {
  margin-top: -30px;
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  margin-right: -100px;
  text-align: right;
}
.vector-7 {
  margin-top: 35px;
  margin-left: 9px;
  margin-bottom: 9px;
}
.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.route-information {
  // @include roboto-12-medium;
  font-size: 12px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: -2px;
  margin-bottom: -2px;
}
.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}
.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  color: rgba(242, 153, 0, 1);
  margin-top: 4px;
  margin-right: 9px;
}
.group-18785 {
  border-radius: 23px;
  height: 29px;
  width: 80px;
  padding: 2px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}
.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  text-align: center;
  position: relative;
}
.btn-plus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: 45px;
}
.btn-minus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: -40px;
}
.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.input-label {
  // left: 4px;
  top: -27px;
}
.onHover {
  cursor: pointer;
}
.delete-card {
  padding-left: 10px;
}
// }
// end cart
</style>

